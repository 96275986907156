import React from "react";
import {useSelector} from 'react-redux'
import Index from "./routes/index"
import {ToastContainer} from 'react-toastify';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/bootstrap.css'
import './assets/css/style_new.css'
import Router from "./pages/styledDemo/Router";

function App() {
    const loader = useSelector(state => state.loader);

    return (
        <>
            <Router/>
            <ToastContainer
                position="bottom-left"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                theme="colored"
            />
        </>


    );
}

export default App;
