import React from 'react'

import Home from '../pages/home/Home'
import Main from "../pages/main/Main";
import Demo from "../pages/demo/Demo";
import StyledDemo from "../pages/styledDemo/StyledDemo";

const publicRoutes = [
	{ path: "/", component: <Demo /> },
	{ path: "/main", component: <Main /> },
	{ path: "/demo", component: <Demo /> },
]

export { publicRoutes }