import React from 'react';
import styled from "styled-components";
import LogoIcon from "../../assets/images/icons/logo.svg";
import {deviceMaxWidth} from "../../helpers/size";

const LogoStyled = () => {
    return (
        <Container>
            <a href="https://erion.world/" target="_blank">
                <LogoImg src={LogoIcon}/>
            </a>
        </Container>
    )
}

export default LogoStyled;

const Container = styled.div`
  position: absolute;
  margin-top: 36px;
  margin-left: 72px;

  @media ${deviceMaxWidth.mobileS}, ${deviceMaxWidth.tablet} {
    margin: auto;
    left: 0;
    right: 0;
    top: 36px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const LogoImg = styled.img`
  width: 200px;
  height: 100px;
`;
