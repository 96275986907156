import styled from "styled-components";
import LogoIcon from "../../assets/images/icons/logo.svg";
import {deviceMaxWidth} from "../../helpers/size";
import MintTopStyled from "./MintTopStyled";
import MintInputStyled from "./MintInputStyled";
import useWallet from "../../hooks/useWallet";
import {useSelector} from "react-redux";
import {useState} from "react";

const MintStyled = () => {

    const MAX_NFT = 2;
    const {isConnectedWallet, account} = useSelector((store) => store.wallet);

    return (
        <>
            <Container>
                <Inner>
                    <MintTopStyled/>
                    <MintInputStyled account={account}/>
                </Inner>
            </Container>
        </>
    )
}

export default MintStyled


const Container = styled.div`
  height: 100vh;
  padding-bottom: 60px;
  padding-top: 60px;
  

  @media ${deviceMaxWidth.mobileS, deviceMaxWidth.tablet} {
    margin: auto;
    left: 0;
    right: 0;
    top: 36px;
    text-align: center;
  }
`;

const Inner = styled.div`
  width: 100%; /* Set width */
  height: 100%; /* Set height */
  box-shadow: 0px 0px 16px 0px rgba(159, 221, 255, 0.6);
  border: 1px solid rgba(30, 175, 255, 1);
  border-right: 0;
  border-radius: 12px 0 0 12px;
  backdrop-filter: blur(16px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  @media ${deviceMaxWidth.tablet} {
    border-left: 0;
    border-radius: 0;
  }

`;

const LogoImg = styled.img``;