import {BrowserRouter, Route, Routes} from 'react-router-dom';
import StyledDemo from "./StyledDemo";
import LayoutStyled from "../../containers/layout/LayoutStyled";
import UpcomingPage from "./UpcomingPage";
import {useEffect, useState} from "react";


const Router = () => {
    const [isUpcoming, setIsUpcoming] = useState(process.env.REACT_APP_IS_UPCOMING === "1");
    const startDate = process.env.REACT_APP_START_DATE;

    const checkTime = () => {
        const tashkentTime = new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Tashkent' }));
        const startTimeInTashkent = new Date(new Date(startDate).toLocaleString('en-US', { timeZone: 'Asia/Tashkent' }));
        const difference = startTimeInTashkent - tashkentTime;

        if (difference <= 0) {
            setIsUpcoming(false);
            return false;
        }
        return true;
    };

    // useEffect(() => {
    //     if (!checkTime()) {
    //         return;
    //     }
    //
    //     const timer = setInterval(() => {
    //         if (!checkTime()) {
    //             clearInterval(timer);
    //         }
    //     }, 1000);
    //
    //     return () => clearInterval(timer);
    // }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<LayoutStyled isUpcoming={isUpcoming} />}>
                    {isUpcoming ?
                        <Route path="/" element={<UpcomingPage />} /> :
                        <Route path="/" element={<StyledDemo />} />
                    }
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
