import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

const axiosInstanceGuest = axios.create({
    baseURL,
    timeout: 100000,
});

const errorHandler = (error, hooks) => {
    console.log(error);
    return Promise.reject(error.response)
}

axiosInstanceGuest.interceptors.request.use(
    config => {
        return config
    },
    error => errorHandler(error)
)

axiosInstanceGuest.interceptors.response.use(response => {
    const res = response.data;
    return response;
}, error => errorHandler(error));


export default axiosInstanceGuest;
