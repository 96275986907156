import styled from "styled-components";
import LogoIcon from "../../assets/images/icons/logo.svg";
import {deviceMaxWidth, deviceMinWidth} from "../../helpers/size";
import TwitterIcon from "../../assets/images/icons/twitter.svg";
import COLORS from "../../helpers/color"
import bgFrame from "../../assets/images/backgrounds/bg_frame.png";
import useWeb3 from "../../hooks/useWeb3";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {WALLET_TYPE} from "../../helpers/helpers";
import {getNftInfoApi} from "../../hooks/useAPI";
import {toast} from "react-toastify";
import ErrorModal from "../../components/ErrorModal";
import SuccessModal from "../../components/SuccessModal";
import LoadingModal from "../../components/LoadingModal";
import DiclaymerModal from "../../components/DiclaymerModal";

const MintInputStyled = () => {
    const MAX_NFT = 2;
    const {isConnectedWallet, walletType, account} = useSelector((store) => store.wallet);
    const [amountWhiteList, setAmountWhiteList] = useState(1)
    const [amountPublicList, setAmountPublicList] = useState(1)
    const [amountPreSaleList, setAmountPreSaleList] = useState(1)
    const [amountAirdrop, setAmountAirdrop] = useState(0)
    const [showLoading, setShowLoading] = useState(false);
    const [whiteListNftInfo, setWhiteListNftInfo] = useState({
        total_amount: 0,
        remain_amount: 0,
        start_date: null,
        type: ''
    });
    const [publicNftInfo, setPublicNftInfo] = useState({
        total_amount: 0,
        remain_amount: 0,
        start_date: null,
        type: ''
    });
    const [preSaleNftInfo, setPreSaleNftInfo] = useState({
        total_amount: 0,
        remain_amount: 0,
        start_date: null,
        type: ''
    });
    const METADATA = "https://gateway.pinata.cloud/ipfs/QmZNcqYRTc5eFDgbbSd6wrMMxbJwiKzJiLmxywXvo4aTXF";
    const {
        getEthBalance, isOperator,
        mintInPublicList,
        mintInPreSale,
        mintInAirdrop,
        mintInWhiteList,
        isPublicMintOpen,
        isPreSaleMintOpen,
        isWhitelistedAccount,
        isWhitelistMintOpen,
        isAirdropMintOpen,
        getMintCountInWhitelist,
        getMintCountInPublic,
        getMintCountInPreSale,
        getMintCountInAirdrop
    } = useWeb3()

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showDeclaymer, setShowDeclaymer] = useState(false);


    useEffect(() => {
        getNftInfo()
    }, [])
    const getNftInfo = async () => {
        const res = await getNftInfoApi();
        console.log(res);
        if (res.code === 200) {
            for (let i = 0; i < res.data.length; i++) {
                const item = res.data[i];
                if (item.type === 'PUBLIC') {
                    setPublicNftInfo({
                        ...publicNftInfo,
                        total_amount: item.total_amount,
                        remain_amount: item.remain_amount,
                        start_date: item.start_date,
                        type: item.type
                    })
                } else if (item.type === 'WHITELIST') {
                    setWhiteListNftInfo({
                        ...whiteListNftInfo,
                        total_amount: item.total_amount,
                        remain_amount: item.remain_amount,
                        start_date: item.start_date,
                        type: item.type
                    })
                } else if (item.type === 'PRESALE') {
                    setPreSaleNftInfo({
                        ...preSaleNftInfo,
                        total_amount: item.total_amount,
                        remain_amount: item.remain_amount,
                        start_date: item.start_date,
                        type: item.type
                    })
                }
            }
        }
    }


    const clickWhiteList = async () => {
        if (!isConnectedWallet) {
            toast.error(
                <div>
                    <div className="fw-600 fz16 mb-5px">Warning</div>
                    <div className="fz14">
                        Please Connect Wallet
                    </div>
                </div>
            )
            return;
        }

        if (amountWhiteList <= 0) {
            setErrorMessage("Amount can not be less 1")
            setShowErrorModal(true);
            return;
        }

        if (amountWhiteList > 2) {
            setErrorMessage("Amount can not be more than 2")
            setShowErrorModal(true);
            return;
        }

        // check whitelist open
        const isOpen = await isWhitelistMintOpen();
        console.log(isOpen);
        if (!isOpen) {
            setErrorMessage("Whitelist is not open")
            setShowErrorModal(true);
            return;
        }
        // // check whitelisted account
        // const isWhiteListedAccount = await isWhitelistedAccount(account);
        // console.log(isWhiteListedAccount);
        // if (!isWhiteListedAccount) {
        //     setErrorMessage("Your wallet is not in whitelist")
        //     setShowErrorModal(true);
        //     return;
        // }
        // check mint count
        const mintedCount = await getMintCountInWhitelist(account);
        console.log(mintedCount);
        if (Number.parseInt(mintedCount) + amountWhiteList > MAX_NFT) {
            setErrorMessage("Max mint count 2")
            setShowErrorModal(true);
            return;
        }

        try {
            setShowLoading(true)
            const res = await mintInWhiteList(account, amountWhiteList, METADATA);
            console.log(res)
            setShowLoading(false)

            setSuccessMessage("Mint Transaction Successfully Completed")
            setShowSuccessModal(true);
            await getNftInfo()
        } catch (e) {
            console.log(e);
            setErrorMessage(e.message)
            setShowErrorModal(true);
            setShowLoading(false)
        }
    }
    const clickPublic = async () => {
        if (!isConnectedWallet) {
            toast.error(
                <div>
                    <div className="fw-600 fz16 mb-5px">Error</div>
                    <div className="fz14">
                        Please Connect Wallet
                    </div>
                </div>
            )
            return;
        }

        if (amountPublicList <= 0) {
            setErrorMessage("Amount can not be less 1")
            setShowErrorModal(true);
            return;
        }

        if (amountPublicList > 2) {
            setErrorMessage("Amount can not be more than 2")
            setShowErrorModal(true);
            return;
        }

        // check whitelist open
        const isOpen = await isPublicMintOpen();
        console.log(isOpen);
        if (!isOpen) {
            setErrorMessage("Whitelist is not open")
            setShowErrorModal(true);
            return;
        }

        // check mint count
        const mintedCount = await getMintCountInPublic(account);
        console.log(mintedCount);
        if (Number.parseInt(mintedCount) + amountPublicList > MAX_NFT) {
            setErrorMessage("Max mint count 2")
            setShowErrorModal(true);
            return;
        }

        try {
            setShowLoading(true)

            // check fee and amount

            const res = await mintInPublicList(account, amountPublicList, METADATA);
            console.log(res)
            setShowLoading(false)
            setSuccessMessage("Mint Transaction Successfully Completed")
            setShowSuccessModal(true);
            await getNftInfo()

        } catch (e) {
            setShowLoading(false)
            console.log(e);
            setErrorMessage(e.message)
            setShowErrorModal(true);
        }
    }
    const clickPresale = async () => {
        if (!isConnectedWallet) {
            toast.error(
                <div>
                    <div className="fw-600 fz16 mb-5px">Error</div>
                    <div className="fz14">
                        Please Connect Wallet
                    </div>
                </div>
            )
            return;
        }

        if (amountPreSaleList <= 0) {
            setErrorMessage("Amount can not be less 1")
            setShowErrorModal(true);
            return;
        }

        if (amountPreSaleList > 2) {
            setErrorMessage("Amount can not be more than 2")
            setShowErrorModal(true);
            return;
        }

        // check whitelist open
        const isOpen = await isPreSaleMintOpen();
        console.log(isOpen);
        if (!isOpen) {
            setErrorMessage("Presale is not open")
            setShowErrorModal(true);
            return;
        }

        // check mint count
        const mintedCount = await getMintCountInPreSale(account);
        console.log(mintedCount);
        if (Number.parseInt(mintedCount) + amountPreSaleList > MAX_NFT) {
            setErrorMessage("Max mint count 2")
            setShowErrorModal(true);
            return;
        }

        try {
            setShowLoading(true)
            // check fee and amount
            const res = await mintInPreSale(account, amountPreSaleList, METADATA);
            console.log(res)
            setShowLoading(false)
            setSuccessMessage("Mint Transaction Successfully Completed")
            setShowSuccessModal(true);
            await getNftInfo()

        } catch (e) {
            setShowLoading(false)
            console.log(e);
            setErrorMessage(e.message)
            setShowErrorModal(true);
        }
    }
    const minusArrow = (type) => {
        if (type === 'PUBLIC') {
            let newAmount = amountPublicList;
            if (newAmount === 0) {
                return
            }
            newAmount = newAmount - 1;
            if (newAmount === 0) {
                return
            }
            setAmountPublicList(newAmount)
        } else if (type === 'WHITELIST') {
            let newAmount = amountWhiteList;
            if (newAmount === 0) {
                return
            }
            newAmount = newAmount - 1;
            if (newAmount === 0) {
                return
            }
            setAmountWhiteList(newAmount)
        } else if (type === 'PRESALE') {
            let newAmount = amountPreSaleList;
            if (newAmount === 0) {
                return
            }
            newAmount = newAmount - 1;
            if (newAmount === 0) {
                return
            }
            setAmountPreSaleList(newAmount)
        }
    }
    const plusArrow = (type) => {
        console.log(type)
        if (type === 'PUBLIC') {
            let newAmount = amountPublicList;
            newAmount = newAmount + 1;
            if (newAmount > MAX_NFT) {
                return
            }
            setAmountPublicList(newAmount)
        } else if (type === 'WHITELIST') {
            let newAmount = amountWhiteList;
            newAmount = newAmount + 1;
            if (newAmount > MAX_NFT) {
                return
            }
            setAmountWhiteList(newAmount)
        } else if (type === 'PRESALE') {
            let newAmount = amountPreSaleList;
            newAmount = newAmount + 1;
            if (newAmount > MAX_NFT) {
                return
            }
            setAmountPreSaleList(newAmount)
        }
    }

    return (
        <>
            <Container>
                <ExtraSpace height="5%"/>
                <Inner>
                    <BackgroundImg src={bgFrame}/>
                    <InputContainer>
                        <TopInput>
                            <TopInputTitle>Whitelist</TopInputTitle>
                            <TopTimer>00:00:00</TopTimer>
                        </TopInput>
                        <CounterContainer>

                            <CounterInner>
                                <div className="mint_input_name"
                                     onClick={() => minusArrow("WHITELIST")}>
                                    <label>-</label>
                                </div>
                                <input
                                    id="formRow-buy-q"
                                    className="demo_mint_info_box_input"
                                    type="number"
                                    min={1}
                                    max={2}
                                    defaultValue={amountWhiteList}
                                    disabled={true}
                                    value={amountWhiteList}
                                    onChange={(e) => setAmountWhiteList(e.target.value)}

                                />
                                <div className="mint_input_name" onClick={() => plusArrow("WHITELIST")}>
                                    <label>+</label>
                                </div>
                            </CounterInner>

                            <CounterLabel>
                                {(amountWhiteList * Number.parseFloat(process.env.REACT_APP_FEE_WHITELIST)).toFixed(1)} ETH
                            </CounterLabel>

                        </CounterContainer>
                        <ControllerContainer>

                            <ControllerLabelContainer>
                                <ControllerLabel>
                                    Total: {whiteListNftInfo.total_amount}
                                </ControllerLabel>
                                <ControllerLabel>
                                    Available: {whiteListNftInfo.remain_amount}
                                </ControllerLabel>
                                <ControllerLabel>
                                    2 per Wallet
                                </ControllerLabel>
                            </ControllerLabelContainer>

                            <ControllerButton onClick={() => clickWhiteList()}>
                                Mint
                            </ControllerButton>
                        </ControllerContainer>
                    </InputContainer>
                </Inner>
                <ExtraSpace height="5%"/>
                <Inner>
                    <BackgroundImg src={bgFrame}/>
                    <InputContainer>
                        <TopInput>
                            <TopInputTitle>Pre-Sale</TopInputTitle>
                            <TopTimer>00:00:00</TopTimer>
                        </TopInput>
                        <CounterContainer>

                            <CounterInner>
                                <div className="mint_input_name"
                                     onClick={() => minusArrow("PRESALE")}>
                                    <label>-</label>
                                </div>
                                <input
                                    id="formRow-buy-q"
                                    className="demo_mint_info_box_input"
                                    type="number"
                                    min={1}
                                    max={2}
                                    defaultValue={amountPreSaleList}
                                    disabled={true}
                                    value={amountPreSaleList}
                                    onChange={(e) => setAmountPreSaleList(e.target.value)}

                                />
                                <div className="mint_input_name" onClick={() => plusArrow("PRESALE")}>
                                    <label>+</label>
                                </div>
                            </CounterInner>

                            <CounterLabel>
                                {(amountPreSaleList * Number.parseFloat(process.env.REACT_APP_FEE_PRESALE)).toFixed(1)} ETH
                            </CounterLabel>

                        </CounterContainer>
                        <ControllerContainer>

                            <ControllerLabelContainer>
                                <ControllerLabel>
                                    Total: {preSaleNftInfo.total_amount}
                                </ControllerLabel>
                                <ControllerLabel>
                                    Available: {preSaleNftInfo.remain_amount}
                                </ControllerLabel>
                                <ControllerLabel>
                                    2 per Wallet
                                </ControllerLabel>
                            </ControllerLabelContainer>

                            <ControllerButton onClick={() => clickPresale()}>
                                Mint
                            </ControllerButton>
                        </ControllerContainer>
                    </InputContainer>
                </Inner>
                <ExtraSpace height="10%"/>
                <Inner>
                    <BackgroundImg src={bgFrame}/>
                    <InputContainer>
                        <TopInput>
                            <TopInputTitle>Public</TopInputTitle>
                            <TopTimer>00:00:00</TopTimer>
                        </TopInput>
                        <CounterContainer>

                            <CounterInner>
                                <div className="mint_input_name" onClick={() => minusArrow("PUBLIC")}
                                >
                                    <label>-</label>
                                </div>
                                <input
                                    id="formRow-buy-q"
                                    className="demo_mint_info_box_input"
                                    type="number"
                                    min={1}
                                    max={2}
                                    defaultValue={amountPublicList}
                                    disabled={true}
                                    value={amountPublicList}
                                    onChange={(e) => setAmountPublicList(e.target.value)}
                                />
                                <div className="mint_input_name" onClick={() => plusArrow("PUBLIC")}>
                                    <label>+</label>
                                </div>
                            </CounterInner>

                            <CounterLabel>
                                {(amountPublicList * Number.parseFloat(process.env.REACT_APP_FEE_PUBLIC)).toFixed(1)} ETH
                            </CounterLabel>

                        </CounterContainer>
                        <ControllerContainer>

                            <ControllerLabelContainer>
                                <ControllerLabel>
                                    Total: {publicNftInfo.total_amount}
                                </ControllerLabel>
                                <ControllerLabel>
                                    Available: {publicNftInfo.remain_amount}
                                </ControllerLabel>
                                <ControllerLabel>
                                    2 per Wallet
                                </ControllerLabel>
                            </ControllerLabelContainer>

                            <ControllerButton onClick={() => clickPublic()}>
                                Mint
                            </ControllerButton>
                        </ControllerContainer>
                    </InputContainer>
                </Inner>

            </Container>

            <ErrorModal show={showErrorModal} onHide={() => setShowErrorModal(false)} content={errorMessage}/>
            <SuccessModal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}
                          content={successMessage}/>
            <LoadingModal show={showLoading} onHide={() => setShowLoading(false)}/>
            <DiclaymerModal show={showDeclaymer} onHide={() => setShowDeclaymer(false)}/>
        </>
    )
}

export default MintInputStyled


const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px 20px;
`;

const Inner = styled.div`
  position: relative;
`;

const InputContainer = styled.div`
  height: 100%;
  position: absolute; /* Position absolutely inside parent */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column; /* Changes the direction to vertical */
`

const TopInput = styled.div`
  height: 20%;
    //background-color: ${COLORS.red};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const TopInputTitle = styled.div`
  position: absolute; /* Positions the timer relative to .demo_mint_whitelist */
  left: 2%; /* Example; adjust as needed */
  /*margin-left: 12px;*/
  font-family: 'Geologica', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0;
  text-align: left;

  @media ${deviceMinWidth.labtopS} {
    font-size: 22px;
    line-height: 0;
  }
  @media ${deviceMaxWidth.tablet} {
    font-size: 20px;
    line-height: 0;
    left: 9%;
  }

`

const TopTimer = styled.div`
  position: absolute; /* Positions the timer relative to .demo_mint_whitelist */
  top: 10%; /* Example; adjust as needed */
  right: 10%; /* Example; adjust as needed */

  @media ${deviceMinWidth.tablet} {
    right: 10%;
  }
  @media ${deviceMaxWidth.mobileS} {
    right: 15%; /* Example; adjust as needed */
  }
`

const CounterContainer = styled.div`
    //background-color: ${COLORS.red};
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center; // Change this line
`;

const CounterInner = styled.div`
  width: 85px;
  display: inline-flex;
  position: relative;
  align-items: center;
  background: #0C446B;
  border-radius: 5px;
  margin-bottom: 5px;
  border: 1px solid #0C446B;
  padding: 9px 0;

  input {
    outline: none;
    border: none;
  }

`

const CounterLabel = styled.div`
  font-family: 'Geologica', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
  margin-left: 15px;
  width: 90px;

`


const ControllerContainer = styled.div`
  height: 40%;
    //background-color: ${COLORS.black};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5%;
  padding-right: 10%;
  padding-left: 10%;

  @media ${deviceMaxWidth.tablet} {
    padding-right: 15%;
    padding-left: 15%;
  }
`;

const ControllerLabelContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`

const ControllerButton = styled.button`
  border: none;
  width: 30%;
  border-radius: 6px;
  box-shadow: 0px 0px 10px 0px rgba(159, 221, 255, 1);
  background: rgba(0, 238, 255, 1);
  color: rgba(12, 68, 107, 1);
  font-family: 'Geologica', sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  height: 35px;

`

const ControllerLabel = styled.div`
  font-family: 'Geologica', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;

  @media ${deviceMinWidth.labtopS} {
    font-size: 14px
  }



`

const ExtraSpace = styled.div`
  height: ${props => props.height || '10%'};
`;

const BackgroundImg = styled.img`
  display: block;
  max-width: 100%;

  @media ${deviceMaxWidth.mobileS, deviceMaxWidth.tablet} {
    padding-left: 20px;
    padding-right: 20px;
    width: auto;
    height: auto;
  }
`;