import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import modalClose from "../assets/images/backgrounds/Close.svg"
import styled from "styled-components";
import bgFrame from "../assets/images/backgrounds/disclaimer.png";
import {deviceMaxWidth} from "../helpers/size";

const DiclaymerModal = ({show, onHide, content}) => {

    return (

        <CustomModal
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={onHide}
            className="declaymer"
        >

            <Container>
                <Inner>
                    <BackgroundImg src={bgFrame}/>
                    <InputContainer>
                        <IconContainer>
                            <img src={modalClose} className="close_icon" onClick={onHide}/>
                        </IconContainer>
                        <TitleContainer>
                                <TopTitle>
                                    Disclaimer
                                </TopTitle>

                        </TitleContainer>
                        <ContentContainer>
                            <ContentText>
                                This game is purely for demonstrational purposes, and any resemblance to elements from popular games or social media platforms is coincidental. The use of logos, trademarks, or other intellectual property belonging to these games and social media platforms on AdBoards and within the Erion city is not intended for commercial gain or endorsement. All rights to the respective logos and trademarks belong to their respective owners. We do not claim ownership or affiliation with these entities, and their use in the game is solely for illustrative and non-commercial purposes. If any issues arise, please contact us to address them promptly.
                            </ContentText>

                        </ContentContainer>

                    </InputContainer>

                </Inner>

            </Container>
        </CustomModal>);
};

const CustomModal = styled(Modal)`
  & .modal-dialog {
    max-width: 90% !important;  // You can adjust this value
    margin-right: 490px;

  }
`;

const Container = styled.div`
  //width: 100%; /* Set the width of the container */
  height: 80%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;

`

const Inner = styled.div`
  width: 100%;
  position: relative;
`;

const BackgroundImg = styled.img`
  display: block;
  max-width: 100%;
  height: 200px;

  @media ${deviceMaxWidth.mobileS, deviceMaxWidth.tablet} {
    padding-left: 20px;
    padding-right: 20px;
    width: auto;
    height: auto;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute; /* Position absolutely inside parent */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column; /* Changes the direction to vertical */
`

const IconContainer = styled.div`
  height: 20%;
`

const TitleContainer = styled.div`
  height: 20%;
`

const ContentContainer = styled.div`
  height: 60%;
  margin-bottom: 24px;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 5px;
  background-color: #0D3358;
`

const TopTitle= styled.div`
  font-family: Geologica;
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0px;
  text-align: start;
  padding-left: 24px;
`

const ContentText= styled.div`
  font-family: Geologica;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: justify;
  padding: 10px;
`
export default DiclaymerModal;