import React, {useEffect, useState} from "react";
import LogoIcon from "../../assets/images/icons/logo.svg";
import VideoBg from "../../assets/images/backgrounds/video_bg.png";
import VideoBgNew from "../../assets/images/backgrounds/subtract.svg";
import RightBg from "../../assets/images/backgrounds/right_bg.png";
import Animation from "../../assets/video/animation1_2.mp4";
import "./Main.css"
import TwitterIcon from "../../assets/images/icons/twitter.svg";
import DiscordIcon from "../../assets/images/icons/discord.svg";
import InstagramIcon from "../../assets/images/icons/instagram.svg";
import DiclaimerIcon from "../../assets/images/icons/diclaimer_img.svg";
import TelegramIcon from "../../assets/images/icons/telegram.svg";
import {truncateAddress, WALLET_TYPE} from "../../helpers/helpers";
import useWallet from "../../hooks/useWallet";
import {useSelector} from "react-redux";
import useWeb3 from "../../hooks/useWeb3";
import {getNftInfoApi} from "../../hooks/useAPI";
import {toast} from "react-toastify";
import bgFrame from "../../assets/images/backgrounds/bg_frame.png";
import {Modal} from "react-bootstrap";
import SuccessModal from "../../components/SuccessModal";
import ErrorModal from "../../components/ErrorModal";
import LoadingModal from "../../components/LoadingModal";
import DiclaymerModal from "../../components/DiclaymerModal";

function Main() {

    const {connectWallet, logoutWallet} = useWallet();
    const {isConnectedWallet, walletType, account} = useSelector((store) => store.wallet);
    const [amountWhiteList, setAmountWhiteList] = useState(1)
    const [amountPublicList, setAmountPublicList] = useState(1)
    const [amountAirdrop, setAmountAirdrop] = useState(0)
    const [showLoading, setShowLoading] = useState(false);
    const [showDeclaymer, setShowDeclaymer] = useState(false);
    const [whiteListNftInfo, setWhiteListNftInfo] = useState({
        total_amount: 0,
        remain_amount: 0,
        start_date: null,
        type: ''
    });
    const [publicNftInfo, setPublicNftInfo] = useState({
        total_amount: 0,
        remain_amount: 0,
        start_date: null,
        type: ''
    });
    const METADATA = "https://gateway.pinata.cloud/ipfs/QmYAeMTUFhYuVuc3oqYv68KUTPFMVMdtZWdkAg3RYWms9H";
    const {getEthBalance, isOperator, mintInPublicList, mintInAirdrop, mintInWhiteList} = useWeb3()

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleNetwork = async () => {
        if (isConnectedWallet) {
            window.ethereum.on('chainChanged', () => {
                window.location.reload()
            })
            window.ethereum.on('accountsChanged', (accounts) => {
                if (account?.includes(accounts[0])) return
                connectWallet(WALLET_TYPE.METAMASK)
            })
            const isUnLocked = await window.ethereum._metamask.isUnlocked()
            if (!isUnLocked) {
                logoutWallet()
            }

        }
    }
    useEffect(() => {
        handleNetwork()
    }, []);
    useEffect(() => {
        getNftInfo()
    }, [])
    const getNftInfo = async () => {
        const res = await getNftInfoApi();
        console.log(res);
        if (res.code === 200) {
            for (let i = 0; i < res.data.length; i++) {
                const item = res.data[i];
                if (item.type === 'PUBLIC') {
                    setPublicNftInfo({
                        ...publicNftInfo,
                        total_amount: item.total_amount,
                        remain_amount: item.remain_amount,
                        start_date: item.start_date,
                        type: item.type
                    })
                } else if (item.type === 'WHITELIST') {
                    setWhiteListNftInfo({
                        ...whiteListNftInfo,
                        total_amount: item.total_amount,
                        remain_amount: item.remain_amount,
                        start_date: item.start_date,
                        type: item.type
                    })
                }
            }
        }
    }
    const clickWhiteList = async () => {
        if (!isConnectedWallet) {
            toast.error(
                <div>
                    <div className="fw-600 fz16 mb-5px">Warning</div>
                    <div className="fz14">
                        Please Connect Wallet
                    </div>
                </div>
            )
            return;
        }

        if (amountWhiteList <= 0) {
            setErrorMessage("Amount can not be less 1")
            setShowErrorModal(true);
            return;
        }

        if (amountWhiteList > 2) {
            setErrorMessage("Amount can not be more than 2")
            setShowErrorModal(true);
            return;
        }

        try {
            setShowLoading(true)
            const res = await mintInWhiteList(account, amountWhiteList, METADATA);
            console.log(res)
            setShowLoading(false)

            setSuccessMessage("Mint Transaction Successfully Completed")
            setShowSuccessModal(true);
            await getNftInfo()
        } catch (e) {
            console.log(e);
            setErrorMessage(e.message)
            setShowErrorModal(true);
            setShowLoading(false)
        }
    }
    const clickPublic = async () => {
        if (!isConnectedWallet) {
            toast.error(
                <div>
                    <div className="fw-600 fz16 mb-5px">Error</div>
                    <div className="fz14">
                        Please Connect Wallet
                    </div>
                </div>
            )
            return;
        }

        if (amountPublicList <= 0) {
            toast.error(
                <div>
                    <div className="fw-600 fz16 mb-5px">Error</div>
                    <div className="fz14">
                        Amount can not be less 1
                    </div>
                </div>
            )
            return;
        }

        if (amountPublicList > 2) {
            toast.error(
                <div>
                    <div className="fw-600 fz16 mb-5px">Error</div>
                    <div className="fz14">
                        Amount can not be more than 2
                    </div>
                </div>
            )
            return;
        }


        try {
            setShowLoading(true)

            // check fee and amount

            const res = await mintInPublicList(account, amountPublicList, METADATA);
            console.log(res)
            setShowLoading(false)
            toast.success('Mint Transaction Successfully Completed', {
                position: toast.POSITION.TOP_CENTER
            });
            toast.success(
                <>
                    <div className="mb-10">Mint Completed</div>
                    <div>Tx Hash : {res}</div>
                </>
            );
            await getNftInfo()

        } catch (e) {
            setShowLoading(false)
            console.log(e);
            setErrorMessage(e.message)
            setShowErrorModal(true);
        }
    }
    const minusArrow = (type) => {
        if (type === 'PUBLIC') {
            let newAmount = amountPublicList;
            if (newAmount === 0) {
                return
            }
            newAmount = newAmount - 1;
            setAmountPublicList(newAmount)
        } else {
            let newAmount = amountWhiteList;
            if (newAmount === 0) {
                return
            }
            newAmount = newAmount - 1;
            setAmountWhiteList(newAmount)
        }
    }
    const plusArrow = (type) => {
        console.log(type)
        if (type === 'PUBLIC') {
            let newAmount = amountPublicList;
            newAmount = newAmount + 1;
            setAmountPublicList(newAmount)
        } else {
            let newAmount = amountWhiteList;
            newAmount = newAmount + 1;
            setAmountWhiteList(newAmount)
        }
    }

    return (
        <>
            <section className="main_container">
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-lg-3 ">
                            <div className="logo_container mt-5">
                                <img src={LogoIcon}/>

                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className="video_container">
                                <div className='video_container_bg'>
                                    <div className='video_background'>
                                        <div className='video_background_inner'>
                                            <img src={VideoBg}/>
                                            <video className='video_content' autoPlay loop muted>
                                                <source src={Animation} type='video/mp4'/>
                                            </video>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="col-lg-3 custom_lg_3">
                            <img className="diclaimer_icon_2" onClick={(e)=>setShowDeclaymer(true)} src={DiclaimerIcon}/>
                            <div className="mint_container_bg ">
                                <div className="mint_background">
                                    <div className="mint_background_inner">
                                        <div className="mint_head">
                                            <div className="icon_box">
                                                <a href="https://twitter.com/ViralaxCity" target="_blank">
                                                    <img src={TwitterIcon} alt=""/>
                                                </a>
                                            </div>
                                            <div className="icon_box">
                                                <a href='https://discord.com/invite/r63VFrcG' target="_blank">
                                                    <img src={DiscordIcon} alt=""/>
                                                </a>
                                            </div>
                                            <div className="icon_box">
                                                <a href='https://www.instagram.com/viralax.city/' target="_blank">
                                                    <img src={InstagramIcon} alt=""/>
                                                </a>
                                            </div>
                                            <div className="icon_box">
                                                <a href='https://t.me/viralaxcity' target="_blank">
                                                    <img src={TelegramIcon} alt=""/>
                                                </a>
                                            </div>
                                            {isConnectedWallet && account !== null ? (

                                                <div className="dropdown-header dropdown">
                                                    <button className="wallet_btn">{truncateAddress(account)}</button>
                                                    <div className="dropdown-content">
                                                        <a href="#" onClick={() => logoutWallet()}>Logout</a>
                                                    </div>
                                                </div>
                                            ) : (
                                                <button className="wallet_btn"
                                                        onClick={() => connectWallet(WALLET_TYPE.METAMASK)}>Wallet</button>)}
                                        </div>

                                        <div className="right_side_box">
                                            <div className="right_side_box_name">Whitelist</div>
                                            <div className="clip_path_div">
                                                <div className="bgFrame_div">
                                                    <div className="timer">Start in 00:00:00</div>
                                                    <div className="coin_input_box">
                                                        <div className="mint_box_inner">
                                                            <div className="mint_input_name"
                                                                 onClick={() => minusArrow("WHITELIST")}>
                                                                <label>-</label>
                                                            </div>
                                                            <input
                                                                id="formRow-buy-q"
                                                                className="mint_info_box_input"
                                                                type="number"
                                                                min={1}
                                                                max={2}
                                                                defaultValue={amountWhiteList}
                                                                disabled={true}
                                                                value={amountWhiteList}
                                                                onChange={(e) => setAmountWhiteList(e.target.value)}
                                                            />
                                                            <div className="mint_input_name"
                                                                 onClick={() => plusArrow("WHITELIST")}>
                                                                <label>+</label>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="mint_nft_price"> {(amountWhiteList * Number.parseFloat(process.env.REACT_APP_FEE_WHITELIST)).toFixed(1)} ETH
                                                        </div>
                                                    </div>

                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <div className="right_side_text">
                                                                Total: {whiteListNftInfo.total_amount}
                                                            </div>
                                                            <div className="right_side_text">
                                                                Available: {whiteListNftInfo.remain_amount}
                                                            </div>
                                                            <div className="right_side_text">
                                                                2 per Wallet
                                                            </div>
                                                        </div>
                                                        <button className="btn_main"
                                                                onClick={() => clickWhiteList()}>Mint
                                                        </button>
                                                    </div>

                                                </div>
                                                <img className="bgFrame_img" src={bgFrame}/>
                                            </div>

                                        </div>

                                        <div className="right_side_box">
                                            <div className="right_side_box_name">Public</div>
                                            <div className="clip_path_div">
                                                <div className="bgFrame_div">
                                                    <div className="timer">Start in 00:00:00</div>
                                                    <div className="coin_input_box">
                                                        <div className="mint_box_inner">
                                                            <div className="mint_input_name"
                                                                 onClick={() => minusArrow("PUBLIC")}>
                                                                <label>-</label>
                                                            </div>
                                                            <input
                                                                id="formRow-buy-q"
                                                                className="mint_info_box_input"
                                                                type="number"
                                                                min={1}
                                                                max={2}
                                                                defaultValue={amountPublicList}
                                                                disabled={true}
                                                                value={amountPublicList}
                                                                onChange={(e) => setAmountPublicList(e.target.value)}
                                                            />
                                                            <div className="mint_input_name"
                                                                 onClick={() => plusArrow("PUBLIC")}>
                                                                <label>+</label>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="mint_nft_price"> {(amountPublicList * Number.parseFloat(process.env.REACT_APP_FEE_PUBLIC)).toFixed(1)} ETH
                                                        </div>
                                                    </div>

                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <div className="right_side_text">
                                                                Total: {publicNftInfo.total_amount}
                                                            </div>
                                                            <div className="right_side_text">
                                                                Available: {publicNftInfo.remain_amount}
                                                            </div>
                                                            <div className="right_side_text">
                                                                2 per Wallet
                                                            </div>
                                                        </div>
                                                        <button className="btn_main"
                                                                onClick={() => clickPublic()}>Mint
                                                        </button>
                                                    </div>

                                                </div>
                                                <img className="bgFrame_img" src={bgFrame}/>
                                            </div>

                                        </div>

                                    </div>
                                    <img className="diclaimer_icon" onClick={(e)=>setShowDeclaymer(true)} src={DiclaimerIcon}/>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <ErrorModal show={showErrorModal} onHide={() => setShowErrorModal(false)} content={errorMessage}/>
                <SuccessModal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}
                              content={successMessage}/>
                <LoadingModal show={showLoading} onHide={() => setShowLoading(false)}/>
                <DiclaymerModal show={showDeclaymer} onHide={() => setShowDeclaymer(false)}/>
            </section>

        </>
    )
}

export default Main