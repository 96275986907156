import styled from "styled-components";
import LogoStyled from "./LogoStyled";
import COLORS from "../../helpers/color";
import VideoStyled from "./VideoStyled";
import {betweenMobileSandTablet, deviceMaxWidth, deviceMinWidth} from "../../helpers/size";
import MintStyled from "./MintStyled";
import useWallet from "../../hooks/useWallet";
import {useSelector} from "react-redux";
import {useState} from "react";
import InfoStyled from "./InfoStyled";

const StyledDemo = () => {

    return (
        <>
            <Container>
                <LeftBar>
                    <LogoStyled/>
                </LeftBar>

                <CenterBar>
                    <VideoStyled/>
                </CenterBar>
                <InfoBar>
                    <InfoStyled/>
                </InfoBar>
                <RightBar>
                    <MintStyled/>
                </RightBar>
            </Container>
        </>
    )
}

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${deviceMaxWidth.mobileL} {
    flex-direction: column; // Stack children vertically on small devices
  }
  @media ${deviceMaxWidth.labtopS} {
    flex-direction: column; // Stack children vertically on small laptops
  }
`;

const LeftBar = styled.div`
  width: 10%;
  @media ${deviceMaxWidth.mobileS} {
    width: 0
  }
  @media ${deviceMaxWidth.labtopS} {
    width: 0
  }

`;

const CenterBar = styled.div`
  width: 60%;
  @media ${deviceMaxWidth.mobileS} {
    width: 100%
  }
  @media ${deviceMaxWidth.labtopS} {
    width: 100%
  }
`;


const InfoBar = styled.div`
  width: 5%;
  @media ${deviceMaxWidth.mobileS} {
    width: 0
  }
  @media ${deviceMaxWidth.labtopS} {
    width: 0
  }
`;

const RightBar = styled.div`
  width: 25%;
  @media ${deviceMaxWidth.labtopS} {
    width: 100%;
  }

  @media ${deviceMinWidth.labtopL} {
    width: 20%;
  }


`;

export default StyledDemo