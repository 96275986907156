import axiosInstance from "../helpers/api/axiosInstance";
import axiosInstanceGuest from "../helpers/api/axiosInstance_guest";

function getPath(payload, url) {
    let iterations = Object.entries(payload).length;
    var pathArr = "?";
    if (url) {
        url.includes("?") ? pathArr = '&' : pathArr = '?'
    }

    for (let key in payload) {
        if (!--iterations) {
            pathArr += key + "=" + payload[key];
        } else {
            pathArr += key + "=" + payload[key] + "&";
        }
    }
    return pathArr;
}

export const getNftInfoApi = (data) => axiosInstanceGuest.get(`api/nft/info`).then((res) => {
    return res.data;
});
