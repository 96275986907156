import styled from "styled-components";
import LogoStyled from "./LogoStyled";
import {betweenMobileSandTablet, deviceMaxWidth, deviceMinWidth} from "../../helpers/size";
import {useEffect, useState} from "react";
import ComunityStyled from "./ComunityStyled";

const UpcomingPage = () => {
    const [timeLeft, setTimeLeft] = useState({});
    const startDate = process.env.REACT_APP_START_DATE;

    useEffect(() => {
        const timer = setInterval(() => {
            const tashkentTime = new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Tashkent' }));
            const startTimeInTashkent = new Date(new Date(startDate).toLocaleString('en-US', { timeZone: 'Asia/Tashkent' }));
            const timeLeft = calculateTimeLeft(tashkentTime, startTimeInTashkent);

            setTimeLeft(timeLeft);
        }, 1000);

        return () => clearInterval(timer);
    }, []);
    const calculateTimeLeft = (currentDate, targetDate) => {
        const difference = targetDate - currentDate;
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }
        return timeLeft;
    };


    return (<>
            <Container>
                <LeftBar>
                    <LogoStyled/>
                </LeftBar>
                <CenterBar>
                    <CenterTextWrapper>
                        <CountDownText>
                            {/*<CountDownText>{`${timeLeft.hours || "0"} : ${timeLeft.minutes || "0"} : ${timeLeft.seconds || "0"}`}</CountDownText>*/}
                            <CountDownText>- - : - -</CountDownText>
                        </CountDownText>
                        <ComingSoonText>Launch soon...</ComingSoonText>
                    </CenterTextWrapper>
                </CenterBar>
                <RightBar>
                    <ComunityStyled/>
                </RightBar>
            </Container>
        </>)
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${deviceMaxWidth.mobileL} {
    flex-direction: column; // Stack children vertically on small devices
  }
  @media ${deviceMaxWidth.labtopS} {
    flex-direction: column; // Stack children vertically on small laptops
  }
`;

const LeftBar = styled.div`
  width: 5%;
  @media ${deviceMaxWidth.mobileS} {
    width: 0
  }
  @media ${deviceMaxWidth.labtopS} {
    width: 0
  }

`;

const CenterBar = styled.div`
  width: 90%;
  height: 100vh; // Full height of the viewport
  @media ${deviceMaxWidth.mobileS} {
    width: 100%
  }
  @media ${deviceMaxWidth.labtopS} {
    width: 100%
  }
`;

const RightBar = styled.div`
  width: 5%;
  display: flex;
  justify-content: flex-end; // Align children to the end of the container
  @media ${deviceMaxWidth.labtopS} {
    width: 100%;
  }

  @media ${deviceMinWidth.labtopM} {
    width: 5%;
  }

  @media ${deviceMinWidth.labtopL} {
    width: 5%;
  }
  @media ${deviceMinWidth.desktop} {
    width: 5%;
  }
`;

const CenterTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; // Take full height of parent
  text-align: center; // For good measure
`;

const CountDownText = styled.p`
  font-size: 150px;
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.8);
  margin: 0;
  padding: 0;

  @media ${deviceMaxWidth.tablet} {
    font-size: 100px;
  }
  
`;

const ComingSoonText = styled.p`
  font-size: 30px;
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.8);
  margin: 0;
  padding: 0;
  @media ${deviceMaxWidth.tablet} {
    font-size: 20px;
  }
`;

export default UpcomingPage