const color = {
  white: "#ffffff",
  black: "#000000",

  main: "#2D3EDE",
  mainRGB: "45, 62, 222",

  red: "#FF4848",
  redRGB: "255, 72, 72",

  gray: "#848996",
  lightGray: "#BCBCC7",
  lighterGray: "#E0E0EA",
  lightestGray: "#F0F0F4",
  lightExtraGray: "#FCFCFC",

  darkGray: "#333333",
  darkestGray: "#0F0F29",
};

export default color;
