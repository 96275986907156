import Web3 from "web3";
import {useSelector} from "react-redux";

import {WALLET_TYPE} from "../helpers/helpers";
import {ViralaxABI} from "../helpers/web3/abi/ViralaxABI";
import {AccountMangerABI} from "../helpers/web3/abi/AccountManagerABI";

const web3 = new Web3(Web3.givenProvider);
const chainId = process.env.REACT_APP_CHAIN_ID;

const contractOperatorManager = process.env.REACT_APP_OPERATOR_MANAGER;
const contractFeeManager = process.env.REACT_APP_FEE_MANAGER;
const contractAccountManager = process.env.REACT_APP_ACCOUNT_MANAGER;
const contractViralaxCity = process.env.REACT_APP_VIRALAX_CITY;
const contractNFTContract = process.env.REACT_APP_NFT_CONTRACT;

const useWeb3 = () => {
    const {account, walletType} = useSelector((store) => store.wallet);

    const getEthBalance = async () => {
        console.log(web3);
        console.log(account);
        if (walletType === WALLET_TYPE.METAMASK) {
            return await web3.eth.getBalance(account);
        }
    }

    const isOperator = async () => {
        let contractViralax = new web3.eth.Contract(ViralaxABI, contractViralaxCity)
        return await contractViralax.methods.isOperator(account).call()
    }

    const getGasPrice = async () => {
        return await web3.eth.getGasPrice();
    }

    const getFeeWhiteListMint =  () => {
        return Web3.utils.toWei(process.env.REACT_APP_FEE_WHITELIST, "ether")
    }

    const getFeePublicMint = () => {
        return Web3.utils.toWei(process.env.REACT_APP_FEE_PUBLIC, "ether")
    }

    const getFeePreSaleMint = () => {
        return Web3.utils.toWei(process.env.REACT_APP_FEE_PRESALE, "ether")
    }

    const isPublicMintOpen = async ()=>{
        let contractViralax = new web3.eth.Contract(ViralaxABI, contractViralaxCity)
        return await contractViralax.methods.isPublicEnabled().call();
    }

    const isWhitelistMintOpen = async ()=>{
        let contractViralax = new web3.eth.Contract(ViralaxABI, contractViralaxCity)
        return await contractViralax.methods.isWhiteListEnabled().call();
    }

    const isPreSaleMintOpen = async ()=>{
        let contractViralax = new web3.eth.Contract(ViralaxABI, contractViralaxCity)
        return await contractViralax.methods.isPreSaleEnabled().call();
    }

    const isAirdropMintOpen = async ()=>{
        let contractViralax = new web3.eth.Contract(ViralaxABI, contractViralaxCity)
        return await contractViralax.methods.isAirdropEnabled().call();
    }

    const isWhitelistedAccount = async (to)=>{
        let contractAccountM = new web3.eth.Contract(AccountMangerABI, contractAccountManager);
        return await contractAccountM.methods.isWhiteList(to).call();
    }


    const getMintCountInWhitelist = async (to) =>{
        let contractViralax = new web3.eth.Contract(ViralaxABI, contractViralaxCity)
        return await contractViralax.methods.getMintCountInWhitelist(to).call();
    }

    const getMintCountInPublic = async (to) =>{
        let contractViralax = new web3.eth.Contract(ViralaxABI, contractViralaxCity)
        return await contractViralax.methods.getMintCountInPublic(to).call();
    }

    const getMintCountInPreSale = async (to) =>{
        let contractViralax = new web3.eth.Contract(ViralaxABI, contractViralaxCity)
        return await contractViralax.methods.getMintCountInPreSale(to).call();
    }


    const getMintCountInAirdrop = async (to) =>{
        let contractViralax = new web3.eth.Contract(ViralaxABI, contractViralaxCity)
        return await contractViralax.methods.getMintCountInAirdrop(to).call();
    }

    const mintInWhiteListGasLimit = async (to, amount, metadata) => {
        let contractViralax = new web3.eth.Contract(ViralaxABI, contractViralaxCity)
        const ethAmount = amount * getFeeWhiteListMint();
        return await contractViralax
            .methods.mintInWhiteList(to, amount, metadata)
            .estimateGas({
                from: account,
                value: ethAmount
            });
    }

    const mintInWhiteList = async (to, amount, metadata) => {
        const fee = Web3.utils.toWei(process.env.REACT_APP_FEE_WHITELIST, "ether");
        let contractViralax = new web3.eth.Contract(ViralaxABI, contractViralaxCity)
        const gasLimit = await mintInWhiteListGasLimit(to, amount, metadata)
        const res = await contractViralax
            .methods.mintInWhiteList(to, amount, metadata)
            .send({
                from: account,
                gas: gasLimit,
                value: amount * fee
            });

        return res.transactionHash;
    }

    const mintInPreSaleGasLimit = async (to, amount, metadata) => {
        console.log("Amount : " + amount)
        let contractViralax = new web3.eth.Contract(ViralaxABI, contractViralaxCity)
        const ethAmount = amount * getFeePreSaleMint();
        return await contractViralax
            .methods.mintInPreSale(to, amount, metadata)
            .estimateGas({
                from: account,
                value: ethAmount
            });
    }

    const mintInPreSale = async (to, amount, metadata) => {
        const fee = Web3.utils.toWei(process.env.REACT_APP_FEE_PRESALE, "ether");
        const ethAmount = amount * fee;
        let contractViralax = new web3.eth.Contract(ViralaxABI, contractViralaxCity);
        const gasLimit = await mintInPreSaleGasLimit(to, amount, metadata);
        const res = await contractViralax
            .methods.mintInPreSale(to, amount, metadata)
            .send({
                from: account,
                gas: gasLimit,
                value: ethAmount
            });
        return res.transactionHash;
    }

    const mintInPublicListGasLimit = async (to, amount, metadata) => {
        console.log("Amount : " + amount)
        let contractViralax = new web3.eth.Contract(ViralaxABI, contractViralaxCity)
        const ethAmount = amount * getFeePublicMint();
        return await contractViralax
            .methods.mintInPublicList(to, amount, metadata)
            .estimateGas({
                from: account,
                value: ethAmount
            });
    }

    const mintInPublicList = async (to, amount, metadata) => {
        const fee = Web3.utils.toWei(process.env.REACT_APP_FEE_PUBLIC, "ether");
        const ethAmount = amount * fee;
        let contractViralax = new web3.eth.Contract(ViralaxABI, contractViralaxCity);
        const gasLimit = await mintInPublicListGasLimit(to, amount, metadata);
        const res = await contractViralax
            .methods.mintInPublicList(to, amount, metadata)
            .send({
                from: account,
                gas: gasLimit,
                value: ethAmount
            });
        return res.transactionHash;
    }

    const mintInAirdrop = async (_accounts, metadata) => {
        let contractViralax = new web3.eth.Contract(ViralaxABI, contractViralaxCity)
        const gasLimit = await contractViralax
            .methods.mintInAirdrop(_accounts, metadata)
            .estimateGas({
                from: account,
            });
        const res = await contractViralax
            .methods.mintInAirdrop(_accounts, metadata)
            .send({
                from: account,
                gas: gasLimit
            });

        return res.transactionHash;
    }


    return {
        getEthBalance,
        mintInWhiteList,
        mintInPublicList,
        mintInAirdrop,
        isOperator,
        mintInPublicListGasLimit,
        mintInWhiteListGasLimit,
        getGasPrice,
        getFeeWhiteListMint,
        getFeePublicMint,
        getMintCountInWhitelist,
        getMintCountInPublic,
        getMintCountInAirdrop,
        isPublicMintOpen,
        isWhitelistMintOpen,
        isAirdropMintOpen,
        isPreSaleMintOpen,
        isWhitelistedAccount,
        getMintCountInPreSale,
        mintInPreSale
    };
}


export default useWeb3;