import styled from "styled-components";
import COLORS from "../../helpers/color";
import UpperBar from "../../assets/images/backgrounds/upper_bar_new.png";

const HeaderStyled = () => {
    return (
        <Container>
            <img src={UpperBar}/>
        </Container>
    )
}

const Container = styled.div`
  position: absolute;
  width: 100%;
  //z-index: 100;
`;

export default HeaderStyled