import styled from "styled-components";
import DiclaimerIcon from "../../assets/images/icons/diclaimer_img.svg";
import {useState} from "react";
import DiclaymerModal from "../../components/DiclaymerModal";
import React from "react";
import {deviceMaxWidth} from "../../helpers/size";

const InfoStyled = () => {
    const [showDeclaymer, setShowDeclaymer] = useState(false);
    return (
        <Container>
            <Inner>
                <img onClick={() => setShowDeclaymer(true)} src={DiclaimerIcon}/>
            </Inner>
            <DiclaymerModal show={showDeclaymer} onHide={() => setShowDeclaymer(false)}/>
        </Container>
    )
}

const Inner = styled.div`
  position: absolute;
  padding: 50px 0;
  bottom: 0;
`

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${deviceMaxWidth.mobileS, deviceMaxWidth.tablet} {
    height: 0;
  }
`;

export default InfoStyled