import styled from "styled-components";
import VideoBgImg from "../../assets/images/backgrounds/video_bg_1.svg";
import Animation from "../../assets/video/animation1_2.mp4";
import COLORS from "../../helpers/color";
import {deviceMaxWidth} from "../../helpers/size";

const VideoStyled = () => {
    return (
        <>
            <Container>
                <Inner>
                    <VideoBg>
                        <BackgroundImg src={VideoBgImg}/>
                        <VideoContainer>
                            <video autoPlay loop muted>
                                <source src={Animation} type="video/mp4"/>
                            </video>
                        </VideoContainer>
                    </VideoBg>
                </Inner>
            </Container>
        </>
    );
};

export default VideoStyled;

const Container = styled.div`
  height: 100vh;
  padding-bottom: 60px;
  padding-top: 60px;
`;

const Inner = styled.div`
  display: flex;
  align-items: end;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media ${deviceMaxWidth.mobileS, deviceMaxWidth.tablet} {
    align-items: center;
  }
`;

const VideoBg = styled.div`
  position: relative;
  width: 100%; // Add this line
  display: flex;
  align-items: center;
  justify-content: right;
`;

const BackgroundImg = styled.img`
  display: block;
  width: 100%;
  height: 70vh; // Keeping height fixed
  max-width: 100%;
  object-fit: fill;
  margin-left: 100%;
  
  @media ${deviceMaxWidth.labtopL} {
    width: auto;
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
    object-fit : none;
    margin-left : 0;
  }

  @media ${deviceMaxWidth.labtopS} {
    width: auto;
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
    object-fit : none;
    margin-left : 0;
  }
`;

const VideoContainer = styled.div`
  position: absolute; // Absolute positioning within VideoBg
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px; // You can adjust this padding as needed
  display: flex;
  align-items: center;
  justify-content: right;
  //margin-left: 10%;


  video {
    max-width: 100%; // Ensure the video doesn't overflow the container
    max-height: 100%;
  }

  @media ${deviceMaxWidth.mobileS, deviceMaxWidth.tablet} {
    video {
      padding-left: 5%;
      padding-right: 5%;
    }
    margin-left: 0;
  }

`;
