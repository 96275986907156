import styled from "styled-components";
import COLORS from "../../helpers/color";
import UpperBar from "../../assets/images/backgrounds/upper_bar_new.png";
import {deviceMaxWidth} from "../../helpers/size";
import TwitterIcon from "../../assets/images/icons/twitter.svg";
import DiscordIcon from "../../assets/images/icons/discord.svg";
import InstagramIcon from "../../assets/images/icons/instagram.svg";
import TelegramIcon from "../../assets/images/icons/telegram.svg";

const ComunityStyled = () => {
    return (
        <Container>
            <Inner>
                <TitleWrapper>
                    <Title>
                        Join Our Community
                    </Title>
                </TitleWrapper>
                <InfoWrapper>
                    <Info>
                        <a href="https://twitter.com/erion_world" target="_blank">
                            <img src={TwitterIcon} alt=""/>
                        </a>
                    </Info>
                    <Info>
                        <a href='https://www.instagram.com/erion_world/' target="_blank">
                            <img src={InstagramIcon} alt=""/>
                        </a>
                    </Info>
                    <Info>
                        <a href='https://discord.gg/hWDXdSnaut' target="_blank">
                            <img src={DiscordIcon} alt=""/>
                        </a>
                    </Info>

                    <Info>
                        <a href='https://t.me/erionworld' target="_blank">
                            <img src={TelegramIcon} alt=""/>
                        </a>
                    </Info>
                </InfoWrapper>
            </Inner>
        </Container>
    )
}

const Container = styled.div`
  width: 300px;
  height: 150px;
  padding-top: 60px;
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 16px 0px rgba(159, 221, 255, 0.6);
  border: 1px solid rgba(30, 175, 255, 1);
  border-right: none; // Remove the right border
  border-radius: 12px 0 0 12px; // Radius for top-left, top-right, bottom-right, bottom-left corners
  backdrop-filter: blur(10px);
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center; // Center children vertically
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //flex-grow: 1;  // Allow it to fill available space
`;

const Title = styled.p`
  margin: 0;  // Remove default margin
  font-size: 15px;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
`;

const InfoWrapper = styled.div`
  display: flex;
  padding-top: 4px;
  gap: 10px;
  justify-content : center;

  @media ${deviceMaxWidth.tablet} {
    gap: 25px;
  }
`

const Info = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background: rgba(6, 91, 153, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

export default ComunityStyled;
