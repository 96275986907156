import React, {useEffect, useState} from "react";
import VideoBg from "../../assets/images/backgrounds/video_bg.png";
import "./Demo.css"
import Animation from "../../assets/video/animation1_2.mp4";
import TwitterIcon from "../../assets/images/icons/twitter.svg";
import DiscordIcon from "../../assets/images/icons/discord.svg";
import InstagramIcon from "../../assets/images/icons/instagram.svg";
import DiclaimerIcon from "../../assets/images/icons/diclaimer_img.svg";
import TelegramIcon from "../../assets/images/icons/telegram.svg";
import {truncateAddress, WALLET_TYPE} from "../../helpers/helpers";
import bgFrame from "../../assets/images/backgrounds/bg_frame.png";
import LogoIcon from "../../assets/images/icons/logo.svg";
import useWallet from "../../hooks/useWallet";
import {useSelector} from "react-redux";
import useWeb3 from "../../hooks/useWeb3";
import {getNftInfoApi} from "../../hooks/useAPI";
import {toast} from "react-toastify";
import ErrorModal from "../../components/ErrorModal";
import SuccessModal from "../../components/SuccessModal";
import LoadingModal from "../../components/LoadingModal";
import DiclaymerModal from "../../components/DiclaymerModal";
import {useMediaQuery} from "react-responsive";

function Demo() {
    const MAX_NFT = 2;
    const {connectWallet, logoutWallet} = useWallet();
    const {isConnectedWallet, walletType, account} = useSelector((store) => store.wallet);
    const [amountWhiteList, setAmountWhiteList] = useState(1)
    const [amountPublicList, setAmountPublicList] = useState(1)
    const [amountAirdrop, setAmountAirdrop] = useState(0)
    const [showLoading, setShowLoading] = useState(false);
    const [whiteListNftInfo, setWhiteListNftInfo] = useState({
        total_amount: 0,
        remain_amount: 0,
        start_date: null,
        type: ''
    });
    const [publicNftInfo, setPublicNftInfo] = useState({
        total_amount: 0,
        remain_amount: 0,
        start_date: null,
        type: ''
    });
    const METADATA = "https://gateway.pinata.cloud/ipfs/QmYAeMTUFhYuVuc3oqYv68KUTPFMVMdtZWdkAg3RYWms9H";
    const {
        getEthBalance, isOperator,
        mintInPublicList,
        mintInAirdrop,
        mintInWhiteList,
        isPublicMintOpen,
        isWhitelistMintOpen,
        isAirdropMintOpen,
        getMintCountInWhitelist,
        getMintCountInPublic,
        getMintCountInAirdrop
    } = useWeb3()

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showDeclaymer, setShowDeclaymer] = useState(false);



    const handleNetwork = async () => {
        if (isConnectedWallet) {
            window.ethereum.on('chainChanged', () => {
                window.location.reload()
            })
            window.ethereum.on('accountsChanged', (accounts) => {
                if (account?.includes(accounts[0])) return
                connectWallet(WALLET_TYPE.METAMASK)
            })
            const isUnLocked = await window.ethereum._metamask.isUnlocked()
            if (!isUnLocked) {
                logoutWallet()
            }

        }
    }
    useEffect(() => {
        handleNetwork()
    }, []);
    useEffect(() => {
        getNftInfo()
    }, [])
    const getNftInfo = async () => {
        const res = await getNftInfoApi();
        console.log(res);
        if (res.code === 200) {
            for (let i = 0; i < res.data.length; i++) {
                const item = res.data[i];
                if (item.type === 'PUBLIC') {
                    setPublicNftInfo({
                        ...publicNftInfo,
                        total_amount: item.total_amount,
                        remain_amount: item.remain_amount,
                        start_date: item.start_date,
                        type: item.type
                    })
                } else if (item.type === 'WHITELIST') {
                    setWhiteListNftInfo({
                        ...whiteListNftInfo,
                        total_amount: item.total_amount,
                        remain_amount: item.remain_amount,
                        start_date: item.start_date,
                        type: item.type
                    })
                }
            }
        }
    }
    const clickWhiteList = async () => {
        if (!isConnectedWallet) {
            toast.error(
                <div>
                    <div className="fw-600 fz16 mb-5px">Warning</div>
                    <div className="fz14">
                        Please Connect Wallet
                    </div>
                </div>
            )
            return;
        }

        if (amountWhiteList <= 0) {
            setErrorMessage("Amount can not be less 1")
            setShowErrorModal(true);
            return;
        }

        if (amountWhiteList > 2) {
            setErrorMessage("Amount can not be more than 2")
            setShowErrorModal(true);
            return;
        }

        // check whitelist open
        const isOpen = await isWhitelistMintOpen();
        console.log(isOpen);
        if (!isOpen) {
            setErrorMessage("Whitelist is not open")
            setShowErrorModal(true);
            return;
        }
        // check mint count
        const mintedCount = await getMintCountInWhitelist(account);
        console.log(mintedCount);
        if  (mintedCount >= MAX_NFT) {
            setErrorMessage("Max mint count 2")
            setShowErrorModal(true);
            return;
        }

        try {
            setShowLoading(true)
            const res = await mintInWhiteList(account, amountWhiteList, METADATA);
            console.log(res)
            setShowLoading(false)

            setSuccessMessage("Mint Transaction Successfully Completed")
            setShowSuccessModal(true);
            await getNftInfo()
        } catch (e) {
            console.log(e);
            setErrorMessage(e.message)
            setShowErrorModal(true);
            setShowLoading(false)
        }
    }
    const clickPublic = async () => {
        if (!isConnectedWallet) {
            toast.error(
                <div>
                    <div className="fw-600 fz16 mb-5px">Error</div>
                    <div className="fz14">
                        Please Connect Wallet
                    </div>
                </div>
            )
            return;
        }

        if (amountPublicList <= 0) {
            setErrorMessage("Amount can not be less 1")
            setShowErrorModal(true);
            return;
        }

        if (amountPublicList > 2) {
            setErrorMessage("Amount can not be more than 2")
            setShowErrorModal(true);
            return;
        }

        // check whitelist open
        const isOpen = await isPublicMintOpen();
        console.log(isOpen);
        if (!isOpen) {
            setErrorMessage("Whitelist is not open")
            setShowErrorModal(true);
            return;
        }
        // check mint count
        const mintedCount = await getMintCountInPublic(account);
        console.log(mintedCount);
        if  (mintedCount >= MAX_NFT) {
            setErrorMessage("Max mint count 2")
            setShowErrorModal(true);
            return;
        }

        try {
            setShowLoading(true)

            // check fee and amount

            const res = await mintInPublicList(account, amountPublicList, METADATA);
            console.log(res)
            setShowLoading(false)
            toast.success('Mint Transaction Successfully Completed', {
                position: toast.POSITION.TOP_CENTER
            });
            toast.success(
                <>
                    <div className="mb-10">Mint Completed</div>
                    <div>Tx Hash : {res}</div>
                </>
            );
            await getNftInfo()

        } catch (e) {
            setShowLoading(false)
            console.log(e);
            setErrorMessage(e.message)
            setShowErrorModal(true);
        }
    }
    const minusArrow = (type) => {
        if (type === 'PUBLIC') {
            let newAmount = amountPublicList;
            if (newAmount === 0) {
                return
            }
            newAmount = newAmount - 1;
            if (newAmount === 0) {
                return
            }
            setAmountPublicList(newAmount)
        } else {
            let newAmount = amountWhiteList;
            if (newAmount === 0) {
                return
            }
            newAmount = newAmount - 1;
            if (newAmount === 0) {
                return
            }
            setAmountWhiteList(newAmount)
        }
    }
    const plusArrow = (type) => {
        console.log(type)
        if (type === 'PUBLIC') {
            let newAmount = amountPublicList;
            newAmount = newAmount + 1;
            if (newAmount > MAX_NFT) {
                return
            }
            setAmountPublicList(newAmount)
        } else {
            let newAmount = amountWhiteList;
            newAmount = newAmount + 1;
            if (newAmount > MAX_NFT) {
                return
            }
            setAmountWhiteList(newAmount)
        }
    }

    return (
        <>

            <div className="demo_container_bg">
                <div className="layout-container no-padding">
                    <div className="leftbar">
                        <div className="logo_container mt-5">
                            <img src={LogoIcon}/>
                        </div>
                    </div>
                    <div className="infobar">
                    </div>
                    <div className="centerbar">
                        <div className="demo_2_container">
                            <div className="demo_2_container_inner">
                                <div className="top-section">
                                </div>
                                <div className="bottom-section">
                                    <div className="video_background_1">
                                        <img src={VideoBg}/>
                                        <div className="video_container11">
                                            <video autoPlay loop muted>
                                                <source src={Animation} type='video/mp4'/>
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="infobar">
                        <div className="declaimer_container">
                            <img onClick={() => setShowDeclaymer(true)} src={DiclaimerIcon}/>
                        </div>
                    </div>
                    <div className="rightbar">
                        <div className="demo_3_container">
                            <div className="demo_3_container_inner">
                                <div className="demo_mint_background">
                                    <div className="demo_mint_head">
                                        <div className="demo_icon_box">
                                            <a href="https://twitter.com/ViralaxCity" target="_blank">
                                                <img src={TwitterIcon} alt=""/>
                                            </a>
                                        </div>
                                        <div className="demo_icon_box">
                                            <a href='https://discord.com/invite/r63VFrcG' target="_blank">
                                                <img src={DiscordIcon} alt=""/>
                                            </a>
                                        </div>
                                        <div className="demo_icon_box">
                                            <a href='https://www.instagram.com/viralax.city/' target="_blank">
                                                <img src={InstagramIcon} alt=""/>
                                            </a>
                                        </div>
                                        <div className="demo_icon_box">
                                            <a href='https://t.me/viralaxcity' target="_blank">
                                                <img src={TelegramIcon} alt=""/>
                                            </a>
                                        </div>
                                        {isConnectedWallet && account !== null ? (
                                            <div className="dropdown">
                                                <button className="demo_wallet_btn">{truncateAddress(account)}</button>
                                                <div className="dropdown-content">
                                                    <a href="#" onClick={() => logoutWallet()}>Logout</a>
                                                </div>
                                            </div>
                                        ) : (
                                            <button className="demo_wallet_btn"
                                                    onClick={() => connectWallet(WALLET_TYPE.METAMASK)}>Wallet</button>)}
                                    </div>
                                    <div className="demo_mint_whitelist">
                                        <div className="demo_bgFrame_container">
                                            <img className="demo_bgFrame_img" src={bgFrame}/>
                                            <div className="demo_mint_public_container">
                                                <div className="demo_mint_public_container1">
                                                    <div className="demo_right_side_box_name">Whitelist</div>
                                                    <div className="demo_timer">Start in 00:00:00</div>
                                                </div>
                                                <div className="demo_mint_public_container2">
                                                    <div className="demo_center_wrapper">
                                                        <div className="demo_mint_box_inner">
                                                            <div className="mint_input_name"
                                                                 onClick={() => minusArrow("WHITELIST")}>
                                                                <label>-</label>
                                                            </div>
                                                            <input
                                                                id="formRow-buy-q"
                                                                className="demo_mint_info_box_input"
                                                                type="number"
                                                                min={1}
                                                                max={2}
                                                                defaultValue={amountWhiteList}
                                                                disabled={true}
                                                                value={amountWhiteList}
                                                                onChange={(e) => setAmountWhiteList(e.target.value)}
                                                            />
                                                            <div className="mint_input_name"
                                                                 onClick={() => plusArrow("WHITELIST")}>
                                                                <label>+</label>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="demo_mint_nft_price">{(amountWhiteList * Number.parseFloat(process.env.REACT_APP_FEE_WHITELIST)).toFixed(1)} ETH
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="demo_mint_public_container3">

                                                    <div className="demo_width_40">
                                                        <div className="right_side_text">
                                                            Total: {whiteListNftInfo.total_amount}
                                                        </div>
                                                        <div className="right_side_text">
                                                            Available: {whiteListNftInfo.remain_amount}
                                                        </div>
                                                        <div className="right_side_text">
                                                            2 per Wallet
                                                        </div>
                                                    </div>
                                                    <div className="demo_width_20"></div>
                                                    <div className="demo_width_40">
                                                        <button className="demo_btn_main"
                                                                onClick={() => clickWhiteList()}>Mint
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="demo_mint_public">
                                        <div className="demo_bgFrame_container">
                                            <img className="demo_bgFrame_img" src={bgFrame}/>

                                            <div className="demo_mint_public_container">
                                                <div className="demo_mint_public_container1">
                                                    <div className="demo_right_side_box_name">Public</div>
                                                    <div className="demo_timer">Start in 00:00:00</div>
                                                </div>
                                                <div className="demo_mint_public_container2">
                                                    <div className="demo_center_wrapper">
                                                        <div className="demo_mint_box_inner">
                                                            <div className="mint_input_name"
                                                                 onClick={() => minusArrow("PUBLIC")}>
                                                                <label>-</label>
                                                            </div>
                                                            <input
                                                                id="formRow-buy-q"
                                                                className="demo_mint_info_box_input"
                                                                type="number"
                                                                min={1}
                                                                max={2}
                                                                defaultValue={amountPublicList}
                                                                disabled={true}
                                                                value={amountPublicList}
                                                                onChange={(e) => setAmountPublicList(e.target.value)}
                                                            />
                                                            <div className="mint_input_name"
                                                                 onClick={() => plusArrow("PUBLIC")}>
                                                                <label>+</label>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="demo_mint_nft_price">{(amountPublicList * Number.parseFloat(process.env.REACT_APP_FEE_PUBLIC)).toFixed(1)} ETH
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="demo_mint_public_container3">

                                                    <div className="demo_width_40">
                                                        <div className="right_side_text">
                                                            Total: {publicNftInfo.total_amount}
                                                        </div>
                                                        <div className="right_side_text">
                                                            Available: {publicNftInfo.remain_amount}
                                                        </div>
                                                        <div className="right_side_text">
                                                            2 per Wallet
                                                        </div>
                                                    </div>
                                                    <div className="demo_width_20"></div>
                                                    <div className="demo_width_40">
                                                        <button className="demo_btn_main"
                                                                onClick={() => clickPublic()}>Mint
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ErrorModal show={showErrorModal} onHide={() => setShowErrorModal(false)} content={errorMessage}/>
            <SuccessModal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}
                          content={successMessage}/>
            <LoadingModal show={showLoading} onHide={() => setShowLoading(false)}/>
            <DiclaymerModal show={showDeclaymer} onHide={() => setShowDeclaymer(false)}/>
        </>
    )
}

export default Demo