import {Modal} from "react-bootstrap";
import React from "react";
import "./Modal.css";

const ErrorModal = ({show, onHide, content}) => {
    return (<Modal
        size="cd"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={onHide}
        className="loadingModal"
    >
        <div className="lds-roller">
            <p className="loading_error_txt">ERROR</p>
            <p className="loading_success_content">{content}</p>
            <button className="loading_btn" onClick={() => onHide()}>Go Back</button>
        </div>
    </Modal>);
};

export default ErrorModal;