import styled from "styled-components";
import COLORS from "../../helpers/color";
import LowerBar from "../../assets/images/backgrounds/lower_bar_new.png";
import {deviceMaxWidth} from "../../helpers/size";
const FooterStyled = () => {
    return (
        <Container>
            <img  src={LowerBar} />
        </Container>
    )
}

const Container = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;

  @media ${deviceMaxWidth.labtopS} {
    position: relative;
  }
`;

export default FooterStyled