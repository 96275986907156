import styled from "styled-components";
import LogoIcon from "../../assets/images/icons/logo.svg";
import {deviceMaxWidth} from "../../helpers/size";
import TwitterIcon from "../../assets/images/icons/twitter.svg";
import useWallet from "../../hooks/useWallet";
import {useSelector} from "react-redux";
import {truncateAddress, WALLET_TYPE} from "../../helpers/helpers";
import React from "react";
import {useEffect} from "react";
import DiscordIcon from "../../assets/images/icons/discord.svg";
import InstagramIcon from "../../assets/images/icons/instagram.svg";
import TelegramIcon from "../../assets/images/icons/telegram.svg";

const MintTopStyled = () => {
    const {connectWallet, logoutWallet} = useWallet();
    const {isConnectedWallet, account} = useSelector((store) => store.wallet);


    const handleNetwork = async () => {
        if (isConnectedWallet) {
            window.ethereum.on('chainChanged', () => {
                window.location.reload()
            })
            window.ethereum.on('accountsChanged', (accounts) => {
                if (account?.includes(accounts[0])) return
                connectWallet(WALLET_TYPE.METAMASK)
            })
            const isUnLocked = await window.ethereum._metamask.isUnlocked()
            if (!isUnLocked) {
                logoutWallet()
            }

        }
    }
    useEffect(() => {
        handleNetwork()
    }, []);

    return (
        <>
            <Container>
                <Info>
                    <a href="https://twitter.com/erion_world" target="_blank">
                        <img src={TwitterIcon} alt=""/>
                    </a>
                </Info>
                <Info>
                    <a href='https://discord.gg/hWDXdSnaut' target="_blank">
                        <img src={DiscordIcon} alt=""/>
                    </a>
                </Info>
                <Info>
                    <a href='https://www.instagram.com/erion_world/' target="_blank">
                        <img src={InstagramIcon} alt=""/>
                    </a>
                </Info>
                <Info>
                    <a href='https://t.me/erionworld' target="_blank">
                        <img src={TelegramIcon} alt=""/>
                    </a>
                </Info>
                {isConnectedWallet && account !== null ? (
                    <div className="dropdown">
                        <WalletButton>{truncateAddress(account)}</WalletButton>
                        <div className="dropdown-content">
                            <a href="#" onClick={() => logoutWallet()}>Logout</a>
                        </div>
                    </div>
                ) : (
                    <WalletButton
                        onClick={() => connectWallet(WALLET_TYPE.METAMASK)}>Wallet</WalletButton>)}


            </Container>
        </>
    )
}

export default MintTopStyled


const Container = styled.div`
  display: flex;
  padding-bottom: 5px;
  padding-top: 24px;
  padding-left: 20px;
  padding-right: 20px;
  gap: 16px;
  justify-content: space-between;

  @media ${deviceMaxWidth.tablet} {
    gap: 25px;
    padding: 10px 20px;
  }
`;

const Info = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 6px;
  background: rgba(6, 91, 153, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

const WalletButton = styled.button`
  border: none;
  width: auto;
  flex-grow: 1;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  border-radius: 6px;
  background: rgba(0, 238, 255, 1);
  box-shadow: 0px 0px 11.199999809265137px 0px rgba(159, 221, 255, 1);
  color: rgba(12, 68, 107, 1);
  font-family: 'Geologica', sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  align-items: center;
  height: 35px;


`