import styled from "styled-components";
import COLORS from "../../helpers/color";
import { Outlet } from "react-router-dom";
import HeaderStyled from "../../pages/styledDemo/HeaderStyled";
import FooterStyled from "../../pages/styledDemo/FooterStyled";
import backgroundImage from '../../assets/images/backgrounds/background_img_001.png';
import videoBackground from '../../assets/video/earth_viralax.mp4';

const LayoutStyled = ({ isUpcoming }) => {
    return (
        <>
                <VideoWrapper>
                    <VideoBackground src={videoBackground} type="video/mp4" autoPlay loop muted />
                </VideoWrapper>
            <Container isUpcoming={isUpcoming}>
                <HeaderStyled />
                <Outlet />
                <FooterStyled />
            </Container>
        </>
    )
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-image: ${(props) => !props.isUpcoming ? `url(${backgroundImage})` : 'none'};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-sizing: border-box;
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: relative;
  z-index: 2; // Bring it above the video
`;

const VideoWrapper = styled.div`
  position: fixed;  // Changed from absolute to fixed
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1; // Behind the main content

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;


export default LayoutStyled;
